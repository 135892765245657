import React from "react";
import AboutArea from "../components/AboutAreaOne";
import Banner from "../components/BannerThree";
import Calendly from "../components/calendly";
import FaqAreaOne from "../components/FaqAreaOne";
import Footer from "../components/FooterTwo";
import NavBar from "../components/NavBar";
import ServiceArea from "../components/ServiceAreaFive";
import WorkProcessOne from "../components/WorkProcessOne";

const HomeOne = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Banner */}
      <Banner />

      {/* About Area */}
      <AboutArea />

      {/* Service Area */}
      <ServiceArea />

      {/* FAQ Area */}
      <FaqAreaOne />

      {/* Work Process */}
      <WorkProcessOne />

      {/* Contact Area */}
      <Calendly />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default HomeOne;
