import React, { useEffect } from "react";

const Calendly = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {/* ========================= contact Area One start =========================*/}
      <div className='contact-area'>
        <div className='container'>
          <div className='contact-inner-1'>
            <img
              className='top_image_bounce animate-img-1'
              src='/assets/img/banner/2.png'
              alt='img'
            />
            <img
              className='top_image_bounce animate-img-2'
              src='/assets/img/about/6.png'
              alt='img'
            />
            <div
              className='wow animated fadeInRight'
              data-aos='fade-left'
              data-aos-delay='200'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0'>
                <h6 className='sub-title'>GET IN TOUCH</h6>
                <h2 className='title'>
                  Bringing Your <span>Vision</span> To Life
                </h2>
                <p className='content'>
                  Let's connect and plan your immigration journey.
                </p>
                {/* Calendly inline widget */}
                <div
                  className="calendly-inline-widget"
                  data-url="https://calendly.com/thiaanimmigration/30min"
                  style={{ minWidth: '320px', height: '700px' }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*========================= contact-inner One end =========================*/}
    </>
  );
};

export default Calendly;
