import React from "react";
import AboutArea from "../components/AboutAreaOne";
import Breadcrumb from "../components/Breadcrumb";
import Calendly from "../components/calendly";
import FaqArea from "../components/FaqAreaOne";
import Footer from "../components/FooterTwo";
import NavBar from "../components/NavBar";
import TeamArea from "../components/Team";
import WorkProcess from "../components/WorkProcessOne";

const About = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"About Us"} />

      {/* About Area */}
      <AboutArea />

      {/* FAQ Area */}
      <FaqArea />

      {/* Team Area */}
      <TeamArea />

      {/* Work Process */}
      <WorkProcess />

      {/* Contact Area */}
      <Calendly />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default About;
