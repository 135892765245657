let countrylist = [
    {
        name: "Canada",
        url: "canada",
        head: "Experience Canada's Beauty, Culture, and Opportunities Like Never Before",
        icon: "assets/img/flags/canada.png",
        banner: "/assets/img/country_banner/canada.jpg",
        img:[],
        des: "Are you ready to embark on an exciting journey to Canada, a land of endless possibilities? At Weleden, we are your trusted partner in realizing your Canadian dream. Whether you're seeking to study, work, reunite with family, or explore the breathtaking landscapes, we have the expertise and services to make it happen.",
        content: [{
            heading: 'Your Canadian Journey Begins Here',
            content: 'Weleden is more than an immigration service; we\'re your bridge to a new life in Canada. Discover the opportunities, culture, and beauty this country has to offer. We\'re committed to providing you with the support and guidance needed to ensure your immigration process is seamless and successful.'
        },
        {
            heading: 'Get Started Today',
            content: 'Ready to start your Canadian journey? Contact us today to explore your immigration options, and let Weleden be your trusted partner on the path to a brighter future in Canada.'
        }],
        service: [{
            heading: "Study Abroad",
            des: "Uncover the world-class education and cultural diversity Canada has to offer. We help you choose the right program and guide you through the application process."
        },
        {
            heading: "Work Permits",
            des: "Open doors to international career opportunities with our assistance in obtaining a work permit, ensuring a smooth transition into the Canadian workforce."
        },
        {
            heading: "Family Sponsorships",
            des: "Reunite with your loved ones in Canada through our family sponsorship services, ensuring your family stays together."
        },
        {
            heading: "Business Immigration",
            des: "Realize your entrepreneurial dreams with our expert guidance in starting or investing in a business in Canada."       
        },
        {
            heading: "Visitor Visa",
            des: "Explore the natural beauty, culture, and friendly communities of Canada with our assistance in obtaining a visitor visa."
        },
        {
            heading: "Provincial Nomination",
            des: "Secure your path to Canadian permanent residency through the Provincial Nominee Program, offering a chance to settle in the province of your choice."
        },
        {
            heading: "Immigration Reconsideration/Reassessment",
            des: "If you've faced immigration setbacks, our appeal services offer a second chance at achieving your Canadian dream."
        }],
    },
    {
        name: "Australia",
        url: "australia",
        head: "Seamless Immigration Solutions for Your Journey to Australia",
        icon: "/assets/img/flags/australia.png",
        banner: "/assets/img/country_banner/australia.jpg",
        img: [],
        des: "Welcome to the land Down Under, where education meets innovation! Australia, with its world-class universities and vibrant cultural scene, is a top choice for international students seeking a quality education. Here's a comprehensive guide to help you navigate the exciting journey of studying in Australia. Australia, known for its diverse landscapes and friendly locals, is also home to some of the finest educational institutions globally. The country's commitment to academic excellence, cutting-edge research, and a multicultural environment make it an ideal destination for students aspiring to broaden their horizons.",
        content: [{
            heading: 'Your Journey Begins Here',
            content: 'Embark on a transformative educational experience in Australia, where academic excellence meets cultural diversity. Prepare for a future filled with opportunities, innovation, and personal growth as you join the ranks of global scholars who have made Australia their academic home. Explore the possibilities, embrace the adventure, and let Australia be the canvas for your educational masterpiece.'
        },
        {
            heading: 'Get Started Today',
            content: 'Ready to start your journey? Contact us today to explore your immigration options, and let Weleden be your trusted partner on the path to a brighter future in Australia.'
        }],
        service: [{
            heading: "Why Choose Australia for Education?",
            des: "High-Quality Education: Australia boasts a robust education system, consistently ranking high in global university rankings. Renowned for its research output and innovative teaching methods, Australian institutions provide a world-class learning experience. Multicultural Environment: Immerse yourself in a diverse and inclusive community that welcomes students from all corners of the globe. This cultural mosaic enhances your learning experience and prepares you for a globalized world. Innovative Research Opportunities: Australia is at the forefront of research and innovation. Students have access to state-of-the-art facilities and collaborate with leading experts, contributing to groundbreaking discoveries."
        },
        {
            heading: "What is the Cost of Education and Living in Australia?",
            des: "While pursuing your dreams in Australia, it's essential to plan your finances. Tuition fees and living expenses vary, but the quality of education and life is worth the investment. Explore various accommodation options, scholarships, and part-time work opportunities to manage your budget effectively."
        },
        {
            heading: "But Which Course is Best to Study in Australia?",
            des: "Australia offers a diverse range of courses across disciplines. Whether you're interested in business, technology, arts, or sciences, Australian universities provide comprehensive programs tailored to your academic and career goals. Research various courses and choose one that aligns with your passions and aspirations."
        },
        {
            heading: "Which Examinations/Tests are Required to Apply and Study in Australia?",
            des: "To embark on your Australian academic journey, you'll typically need to take standardized tests such as the IELTS, TOEFL, or PTE for English proficiency. Additionally, specific courses and universities may require subject-specific exams or portfolios. Be sure to check the admission requirements of your chosen institution."       
        },
        {
            heading: "Are Scholarships Available for Studying in Australian Universities?",
            des: "Australia offers a range of scholarships to international students based on academic merit, leadership skills, and other criteria. Explore scholarship opportunities offered by universities, government programs, and private organizations to make your education more affordable."
        },
        {
            heading: "Visa to Study in Australia",
            des: "Securing a student visa is a crucial step in your journey. The Australian government provides streamlined visa processes for international students. Familiarize yourself with the visa requirements, documentation, and application process to ensure a smooth transition to student life in Australia."
        }],
    },
    {
        name: "Ireland",
        url: "ireland",
        head: "Seamless Immigration Solutions for Your Journey to Ireland",
        icon: "/assets/img/flags/ireland.png",
        banner: "/assets/img/country_banner/ireland.jpg",
        img: [],
        des: "Welcome to the Emerald Isle, where a rich history, warm hospitality, and world-class education converge. Choosing to study in Ireland is an invitation to immerse yourself in a land of literary traditions, breathtaking landscapes, and cutting-edge academic excellence. Let's explore why Ireland is a compelling destination for higher education.",
        content: [{
            heading: 'Your Journey Begins Here',
            content: 'Embark on a transformative educational journey in Ireland, where academic excellence meets cultural richness. Let Ireland be the canvas for your growth and exploration, as you join a community of scholars dedicated to shaping the future. Explore the possibilities, seize the opportunities, and let Ireland be the destination for your educational aspirations.'
        },
        {
            heading: 'Get Started Today',
            content: 'Ready to start your journey? Contact us today to explore your immigration options, and let Weleden be your trusted partner on the path to a brighter future in Australia.'
        }],
        service: [{
            heading: "What is the Cost of Studying in Ireland?",
            des: "Understanding the financial aspects is crucial for planning your academic journey. Explore the cost of tuition, accommodation, and daily expenses to ensure a well-prepared and enjoyable experience."
        },
        {
            heading: "Which are the top courses to study in Ireland?",
            des: "Ireland offers a diverse range of courses, from arts and humanities to technology and sciences. Explore the programs that align with your academic and career goals, shaping a future in your chosen field."
        },
        {
            heading: "Which Examinations/Tests are Required to Apply and Study in Ireland?",
            des: "Most Irish universities require international students to demonstrate their proficiency in English through standardized tests such as the IELTS, TOEFL, or PTE. Additionally, specific courses may have additional entry requirements, such as subject-specific exams or portfolios. Ensure you are aware of the admission criteria for your chosen institution."       
        },
        {
            heading: "What About Scholarships to Study in Ireland?",
            des: "Ireland provides various scholarships for international students based on academic merit, leadership qualities, and other criteria. Explore scholarship opportunities offered by universities, government programs, and private organizations to make your education in Ireland more financially accessible."
        },
        {
            heading: "Visa to Study in Ireland",
            des: "Securing a student visa is a crucial step in your academic journey to Ireland. Understand the visa requirements, documentation, and application process to ensure a smooth transition to student life in this culturally rich country."
        }],
    },
    {
        name: "USA",
        url: "united_states",
        head: "Unlock Your American Dream: Expert Immigration Services to the USA",
        icon: "/assets/img/flags/united-states.png",
        banner: "/assets/img/country_banner/USA.jpg",
        img: [],
        des: "Welcome to the United States of America, a land synonymous with innovation, diversity, and academic excellence. Choosing to study in the USA opens doors to a world-class education and a vibrant cultural experience. Let's explore why the USA is a top destination for international students and what you need to know about pursuing your educational journey there. The USA, a global leader in education and technology, beckons students from every corner of the world. With its renowned universities, cutting-edge research facilities, and a commitment to fostering talent, the USA offers an unparalleled academic experience.",
        content: [{
            heading: 'Your Journey Begins Here',
            content: 'Embark on a transformative educational journey in the USA, where academic excellence meets cultural richness. Let the USA be the canvas for your growth and exploration, as you join a community of scholars dedicated to shaping the future. Explore the possibilities, seize the opportunities, and let the USA be the destination for your educational aspirations.'
        },
        {
            heading: 'Get Started Today',
            content: 'Ready to start your journey? Contact us today to explore your immigration options, and let Weleden be your trusted partner on the path to a brighter future in Australia.'
        }],
        service: [{
            heading: "Why Choose the USA?",
            des: "Academic Excellence: The USA is home to some of the world's top-ranking universities, known for their rigorous academic standards, innovative research, and world-class faculty. Diverse Learning Environment: Immerse yourself in a melting pot of cultures, ideas, and perspectives. The USA's diverse community fosters a rich and dynamic learning environment. Opportunities for Research and Innovation: Engage in groundbreaking research projects and contribute to advancements in various fields. Global Networking: Connect with professionals and academics worldwide through extensive alumni networks and industry collaborations. Cultural Richness: Experience the unique blend of cultural events, festivals, and traditions that make the USA a vibrant and exciting place to study. Career Opportunities: Benefit from access to a vast array of internship and job opportunities, setting the stage for a successful career."
        },
        {
            heading: "What is the Cost of Education and Living in USA?",
            des: "Understanding the financial aspects is crucial for planning your educational journey in the USA. Explore the tuition fees, cost of living, and insurance requirements to ensure a well-prepared and enjoyable experience. Tuition Fee: Tuition fees vary based on the institution and the chosen course. Research and understand the cost structure of your selected program to plan your finances effectively. Cost of Living: Consider accommodation, transportation, food, and other daily expenses when estimating the cost of living. Explore affordable living options and create a budget that aligns with your financial resources. Insurance: Health insurance is often mandatory for international students in the USA. Familiarize yourself with the health coverage options provided by your institution or explore external insurance providers."
        },
        {
            heading: "But Which Course is Best to Study in USA?",
            des: "The USA offers a diverse range of courses catering to various interests and career paths. Whether you're inclined towards business, technology, arts, or sciences, American universities provide top-notch programs aligned with industry demands. Some are: Computer Science and Engineering, Business Administration and Management, Health Sciences and Medicine, Data Science and Analytics, Environmental Science and Sustainability."
        },
        {
            heading: "Which Examinations/Tests are Required to Apply and Study in USA?",
            des: "Most US universities require international students to demonstrate their proficiency in English through standardized tests such as the TOEFL or IELTS. Additionally, specific courses may have additional entry requirements, such as GRE or GMAT scores. Ensure you are aware of the admission criteria for your chosen institution."       
        },
        {
            heading: "Are Scholarships Available for Studying in American Universities?",
            des: "The USA offers a range of scholarships to international students based on academic merit, leadership qualities, and other criteria. Explore scholarship opportunities provided by universities, government programs, and private organizations to make your education in the USA more financially accessible."
        },
        {
            heading: "Visa to Study in USA",
            des: "Securing a student visa is a crucial step in your journey to study in the USA. Understand the visa requirements, documentation, and application process to ensure a smooth transition to student life in this land of opportunities."
        }],
    },
    {
        name: "UK",
        url: "united_kindom",
        head: "Your Path to the UK: Exceptional Immigration Services Tailored for Success.",
        icon: "/assets/img/flags/united-kingdom.png",
        banner: "/assets/img/country_banner/UK.jpg",
        img: [],
        des: "Embark on a transformative educational journey in the United Kingdom, where tradition meets innovation. The UK, with its storied history, cutting-edge research, and world-renowned universities, is a beacon for international students seeking excellence. Let's dive into the details that make studying in the UK a truly enriching experience.",
        content: [{
            heading: 'Your Journey Begins Here',
            content: 'Embark on a transformative educational experience in the United Kingdom, where tradition and innovation converge. Let the UK be the canvas for your academic and personal growth, as you join a community of scholars dedicated to shaping the future. Explore the possibilities, seize the opportunities, and let the UK be the gateway to your academic aspirations. The United Kingdom, a melting pot of culture and academia, beckons students from around the world to its hallowed halls of learning. With a rich history and a commitment to academic excellence, the UK offers a diverse and vibrant environment for students to thrive.'
        },
        {
            heading: 'Get Started Today',
            content: 'Ready to start your journey? Contact us today to explore your immigration options, and let Weleden be your trusted partner on the path to a brighter future in Australia.'
        }],
        service: [{
            heading: "Why Choose the UK?",
            des: "Academic Excellence: The UK is home to some of the world's top-ranking universities, known for their rigorous academic standards and cutting-edge research. Immerse yourself in a culture of learning that fosters critical thinking and innovation. Cultural Diversity: Experience a truly global community, where students from different backgrounds come together to create a rich tapestry of cultures. This cultural exchange enhances your education and prepares you for a globalized world. Historical Significance: Study in the footsteps of literary giants, scientific pioneers, and influential thinkers. The UK's historical significance adds a unique dimension to your educational experience. Global Networking Opportunities: Connect with professionals and academics from around the world through the UK's extensive alumni network and industry partnerships. Research and Innovation: Engage with groundbreaking research and innovation, contributing to advancements in various fields. Language Advantage: Improve your English language skills in an immersive environment, enhancing your communication and employability."
        },
        {
            heading: "What is the Cost of Education and Living in the UK?",
            des: "Understanding the financial aspects is crucial for a successful academic journey in the UK. While tuition fees vary, there are numerous scholarships, part-time work opportunities, and cost-effective living arrangements to help manage expenses. Plan your budget wisely to make the most of your time in the UK."
        },
        {
            heading: "But Which Course is Best to Study in the UK?",
            des: "The UK offers a diverse array of courses across disciplines. Whether you're interested in arts, sciences, business, or technology, UK universities provide comprehensive programs designed to meet the demands of a dynamic job market. Explore your options and choose a course that aligns with your passions and career goals."
        },
        {
            heading: "Which Examinations/Tests are Required to Apply and Study in UK?",
            des: "Most UK universities require international students to demonstrate their English language proficiency through tests such as IELTS, TOEFL, or PTE. Additionally, specific courses may have additional entry requirements, such as subject-specific exams or portfolios. Be sure to check the admission criteria of your chosen institution."       
        },
        {
            heading: "Are Scholarships Available for Studying in the Universities in the UK?",
            des: "The UK offers a range of scholarships to international students based on academic merit, leadership skills, and other criteria. Explore scholarship opportunities provided by universities, government programs, and private organizations to make your education in the UK more accessible."
        },
        {
            heading: "Visa to Study in the UK",
            des: "Securing a student visa is a crucial step in your academic journey to the UK. The application process is streamlined, and the UK government provides support to international students. Familiarize yourself with visa requirements, documentation, and application procedures to ensure a smooth transition to student life in the UK."
        }],
    },
    {
        name: "New Zealand",
        url: "new_zealand",
        head: "Chart Your Course to New Zealand: Unparalleled Immigration Services for a Bright Future.",
        icon: "/assets/img/flags/new-zealand.png",
        banner: "/assets/img/country_banner/New-Zealand.jpg",
        img: [],
        des: "Embark on a transformative academic journey in the enchanting landscapes of New Zealand. Renowned for its world-class education system, welcoming culture, and unparalleled natural beauty, New Zealand stands as an ideal destination for higher studies. Explore why choosing New Zealand could be the key to unlocking your educational and personal potential. Nestled in the heart of the Pacific, New Zealand is a land of diverse landscapes, from majestic mountains to pristine beaches. Beyond its stunning scenery, the country offers a high-quality education system that attracts students from around the world.",
        content: [{
            heading: 'Your Journey Begins Here',
            content: 'Embark on a transformative educational journey in New Zealand, where academic excellence meets natural wonders. Let New Zealand be the canvas for your growth and exploration, as you join a community of scholars dedicated to shaping the future. Explore the possibilities, seize the opportunities, and let New Zealand be the destination for your educational aspirations.'
        },
        {
            heading: 'Get Started Today',
            content: 'Ready to start your journey? Contact us today to explore your immigration options, and let Weleden be your trusted partner on the path to a brighter future in New Zealand.'
        }],
        service: [{
            heading: "Why Choose the New Zealand?",
            des: "Academic Excellence: New Zealand is home to universities and institutions known for their academic rigour, cutting-edge research, and global recognition. Safety and Quality of Life: Experience the warmth of Kiwi hospitality in a safe and welcoming environment. New Zealand consistently ranks high in global safety and quality of life indices. Cultural Diversity: Immerse yourself in a multicultural society that values inclusivity and fosters a sense of belonging for international students."
        },
        {
            heading: "What is the Cost of Education and Living in the New Zealand?",
            des: "Understanding the financial aspects of studying in New Zealand is essential for a smooth and successful experience. Plan your budget by considering tuition fees, cost of living, communication fees, personal expenses, and incidentals. Consider accommodation, transportation, groceries, and other daily expenses when estimating the cost of living. New Zealand offers various accommodation options, from on-campus residences to private rentals."
        },
        {
            heading: "But Which Course is Best to Study in the New Zealand?",
            des: "In New Zealand, the best course to study depends on individual interests and career goals. Information Technology, Engineering, and Business and Management are popular choices due to the country's thriving IT sector, strong engineering disciplines, and robust business environment. Health Sciences, particularly nursing and medicine, are well-regarded, as are courses in Tourism and Hospitality, given New Zealand's picturesque landscapes. Additionally, Creative Arts, Agriculture and Environmental Science, and Social Sciences offer diverse options for international students in pursuit of quality education."
        }],
    }
  ];
  export default countrylist;
  