import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
import ReCAPTCHA from 'react-google-recaptcha';

const ContactMain = () => {
  const form = useRef();
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const handleCaptchaChange = (value) => {
    setIsCaptchaVerified(true);
  };
  const sendEmail = (e) => {
    e.preventDefault();
    if (isCaptchaVerified) {
      emailjs
        .sendForm(
          "service_ckllcoc", //YOUR_SERVICE_ID
          "template_d0h9iw4", //YOUR_TEMPLATE_ID
          form.current,
          "iy24NF7aqBBM37cuw" //YOUR_PUBLIC_KEY
        )
        .then(
          (result) => {
            if (result.text === "OK") {
              toast.success("Message Sent Successfully!");
              form.current[0].value = "";
              form.current[1].value = "";
              form.current[2].value = "";
              form.current[3].value = "";
            }
          },
          (error) => {
            if (error.text !== "OK") {
              toast.success("Message Not Sent!");
            }
          }
        );
    } else {
      toast.error("Please verify that you are not a robot.");
    }    
  };
  return (
    <>
      <Toaster position='bottom-center' reverseOrder={false} />
      <div className='contact-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='contact-page-inner bg-gray'>
            <div className='section-title mb-4 pb-2'>
              <h2 className='title'>Want to know more? </h2>
              <p className='content mb-0'>
                We will help you understand the entire process of immigration and guide you towards achieving your dreams!
              </p>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='name'
                      name='user_name'
                      type='text'
                      placeholder='Name'
                      required
                    />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='email'
                      name='user_email'
                      type='email'
                      placeholder='Email.'
                      required
                    />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <input
                      id='subject'
                      name='subject'
                      type='text'
                      placeholder='Subject'
                      required
                    />
                  </div>
                </div>
                <div className='col-12'>
                  <div className='single-input-inner'>
                    <textarea
                      name='message'
                      id='message'
                      cols='1'
                      rows='5'
                      placeholder='Enter Your Message...'
                      required
                    />
                  </div>
                </div>
                <div className='col-12 text-center'>
                  <ReCAPTCHA sitekey='6Lf_om8pAAAAANgXxg5j4BHS2l6eNNsOA2Bk5mVX' onChange={handleCaptchaChange} />
                  <button
                    className='btn btn-base border-radius-5'
                    type='submit'
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='contact-page-list'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6 d-flex'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='assets/img/icon/13.svg' alt='img' />
                </div>
                <div className='media-body'>
                  <h5>Contact</h5>
                  <h6>+1-416-476-1878</h6>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 d-flex'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='assets/img/icon/14.svg' alt='img' />
                </div>
                <div className='media-body'>
                  <h5>Email</h5>
                  <h6>immigration@thiaan.com</h6>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 d-flex'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='assets/img/icon/15.svg' alt='img' />
                </div>
                <div className='media-body'>
                  <h5>Location</h5>
                  <p>7 Anderson Drive, Cambridge ON N1R 6E6, CANADA</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='contact-g-map'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2901.5824458180027!2d-80.29568292461501!3d43.34392592238017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4253cce4d02c57d7%3A0x6326020bef39f60!2sThiaan%20International%20Immigration%20services!5e0!3m2!1sen!2sin!4v1704095555870!5m2!1sen!2sin" />
      </div>
    </>
  );
};

export default ContactMain;
