import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Country from "./pages/Country"; 
import Contact from "./pages/Contact";
import HomeOne from "./pages/HomeOne";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import countrylist from "./scripts/country";
import servicelist from "./scripts/service";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomeOne />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/service' element={<Service />} />
        {/* Dynamic routes for services */}
        {servicelist.map((service) => (
          <Route key={service.title} exact path={`/service/:url`} element={<ServiceDetails />}
          />
        ))}
        {/* Dynamic routes for countries */}
        {countrylist.map((country) => (
          <Route key={country.name} exact path={`/country/:url`} element={<Country />} />
        ))}
        {/* Add a 404 route if needed */}
        <Route path='*' element={<div>404 Not Found</div>} />
      </Routes>
      <ScrollToTop smooth color='#246BFD' />
    </BrowserRouter>
  );
}

export default App;
