import React from "react";
import DetailsArea from "../components/CountryDetails";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/FooterTwo";
import NavBar from "../components/NavBar";
import countrylist from "../scripts/country";
import { useParams } from 'react-router-dom';

const Country = () => {
  const { url } = useParams();
  const countryData = countrylist.find((country) => country.url === url);
  
  
  if (!countryData) {
    return <div>Country not found</div>;
  }

  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Breadcrumb */}
      <Breadcrumb title={countryData.name} data={countryData}/>

      {/* Details Area */}
      <DetailsArea data={countryData} />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Country;
