import React from "react";
import {
  FaArrowRight,
  FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import servicelist from "../scripts/service";

const ServiceDetailsArea = ({data}) => {
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_catagory'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> Other Services
                  </h5>
                  <ul className='catagory-items'>
                  {servicelist.map((data1, index) => (
                    data1.title !== data.title && (
                      <li key={index}>
                        <Link to={`/service/${data1.url}`}>{data1.title}</Link>
                      </li>
                    )
                  ))}

                  </ul>
                </div>
                <div className='widget widget_author text-center'>
                  <div className='thumb'>
                    <img src='/assets/img/sidebar.png' alt='img' />
                  </div>
                  <div className='details'>
                    <a className='btn btn-base border-radius-5' href='/about'>
                      Discover Weleden +
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div className='thumb'>
                    <img src={data.banner} alt='img' />
                  </div>
                  <div className='details'>
                    <h4>{data.des}</h4>
                    <p>{data.content}</p>
                    {data.faqs.map((service, index) => (
                    <div
                      key={index}
                      className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id={`heading${index}`}>
                          <button
                            className='accordion-button'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target={`#collapse${index}`}
                            aria-expanded={index === 0 ? "true" : "false"}
                            aria-controls={`collapse${index}`}
                          >
                            {service.question}
                          </button>
                        </h2>
                        <div
                          id={`collapse${index}`}
                          className={`accordion-collapse collapse ${
                            index === 0 ? "show" : ""
                          }`}
                          aria-labelledby={`heading${index}`}
                        >
                          <div className='accordion-body'>{service.answer}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                    <br />
                    <h4>Keys Points:</h4>
                    <p>{data.conclude}</p>
                    <div className='row'>
                      <div className='col-md-6'>
                        <ul className='single-list-inner style-check style-check mb-3'>
                          {data.points.slice(0, Math.ceil(data.points.length / 2)).map((data1, index) => (
                            <li key={index}>
                              <FaCheckCircle /> {data1}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className='col-md-6'>
                        <ul className='single-list-inner style-check style-check mb-3'>
                          {data.points.slice(Math.ceil(data.points.length / 2)).map((data1, index) => (
                            <li key={index}>
                              <FaCheckCircle /> {data1}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default ServiceDetailsArea;
