// import React, { useState } from "react";
import React from "react";
import { FaPlus } from "react-icons/fa";
// import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";

const BannerThree = () => {
  // const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div
        className='banner-area bg-relative banner-area-2 bg-cover'
        style={{ backgroundImage: 'url("/assets/img/banner-3/5.png")' }}
      >
        <img className='bg-img-2' src='/assets/img/banner-3/4.png' alt='img' />
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 align-self-center'>
              <div className='banner-inner pe-xl-5'>
                <h6
                  className='subtitle '
                  data-aos='fade-right'
                  data-aos-delay='100'
                  data-aos-duration='1500'
                >
                  UNLOCK YOUR DREAMS
                </h6>
                <h2
                  className='title'
                  data-aos='fade-right'
                  data-aos-delay='200'
                  data-aos-duration='1500'
                >
                  Expert <span>Immigration</span> consultant
                </h2>
                <p
                  className='content pe-xl-5'
                  data-aos='fade-right'
                  data-aos-delay='250'
                  data-aos-duration='1500'
                >
                  Are you planning to start a new chapter of your life? Look no further! <br/>Our expert immigration consultants are here to guide you through the intricate process of relocating. Discover the possibilities and opportunities that awaits just for you with our personalized and professional immigration services.
                </p>
                <Link
                  className='btn btn-border-base '
                  data-aos='fade-right'
                  data-aos-delay='300'
                  data-aos-duration='1500'
                  to='/about'
                >
                  Know More <FaPlus />
                </Link>
                {/* <div
                  className='d-inline-block align-self-center wow animated fadeInLeft'
                  data-aos='fade-right'
                  data-aos-delay='300'
                  data-aos-duration='1500'
                >
                  <span
                    onClick={() => setOpen(true)}
                    className='video-play-btn-hover'
                  >
                    <img src='assets/img/video.svg' alt='img' />{" "}
                    <h6 className='d-inline-block'>how we work</h6>
                  </span>
                </div>
                <ModalVideo
                  channel='youtube'
                  autoplay
                  isOpen={isOpen}
                  videoId='XM6kTQPzzpQ'
                  onClose={() => setOpen(false)}
                /> */}
              </div>
            </div>
            <div className='col-lg-5 col-md-10'>
              <div
                className='banner-thumb-3'
                data-aos='fade-left'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <div className='main-img-wrap'>
                  <img
                    className='banner-animate-img banner-animate-img-1 left_image_bounce'
                    src='/assets/img/banner-3/plane.png'
                    alt='img'
                  />
                  <img
                    className='banner-animate-img banner-animate-img-2 left_image_bounce'
                    src='/assets/img/banner-3/travel.png'
                    alt='img'
                  />
                  <img
                    className='main-img'
                    src='/assets/img/banner-3/home.png'
                    alt='img'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerThree;
