import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <img
                  className='animate-img-3 top_image_bounce'
                  src='/assets/img/banner/5.svg'
                  alt='img'
                />
                <img
                  className='main-img'
                  src='/assets/img/Founder.png'
                  alt='Founder-Reena Pol'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h6 className='sub-title'>ABOUT US</h6>
                <h2 className='title'>
                  Welcome to <span>Weleden</span>!
                </h2>
                <p className='content mb-4 mb-xl-5'>
                We help you achieve a New Life Abroad. Navigating the complex world of immigration can be overwhelming, but with Weleden, you're in capable hands. Our team of experienced immigration consultants specializes in facilitating smooth transitions for individuals and families looking to move to Canada, the UK, Ireland, Australia, Ireland or the US.
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='/assets/img/service-icon/comprehensive.png' alt='Comprehensive Services' />
                      </div>
                      <div className='details'>
                        <h5>Comprehensive Services</h5>
                        <p>
                        From initial consultations to visa applications and settlement support, we offer a comprehensive suite of services tailored to your unique needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='/assets/img/service-icon/network.png' alt='Global Network' />
                      </div>
                      <div className='details'>
                        <h5>Global Network</h5>
                        <p>
                        With a strong presence in Canada and a global network of partners, we bring together local insights and global expertise to ensure your efficient immigration journey.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutAreaOne;
