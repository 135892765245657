import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/FooterTwo";
import NavBar from "../components/NavBar";
import ServiceDetailsArea from "../components/ServiceDetailsArea";
import servicelist from "../scripts/service";
import { useParams } from "react-router-dom";

const ServiceDetails = () => {
  const { url } = useParams();
  const ServiceData = servicelist.find((service) => service.url === url);
  if (!ServiceData) {
    return <div>Service not found</div>;
  }
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={ServiceData.title} />

      {/* Service Details Area */}
      <ServiceDetailsArea data={ServiceData}/>

      {/* Footer*/}
      <Footer />
    </>
  );
};

export default ServiceDetails;
