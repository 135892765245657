import React from "react";
import {
  FaChevronRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import servicelist from "../scripts/service";

const FooterTwo = () => {
  return (
    <>
      <footer className='footer-area footer-area-2 bg-gray mt-0 pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src='/assets/img/logo/trans_logo.png' alt='Weleden Logo' />
                  <br/><br/>CICC Registration No.: R707229
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 ps-xl-5'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Our Services</h4>
                <ul>
                  {servicelist.map((data, index) => (
                    <li key={index}>
                      <Link to={`/service/${data.url}`}><FaChevronRight /> {data.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* <div className='col-lg-3 col-md-6 ps-xl-5'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Pay links</h4>
                <ul>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Credit industrys
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Reasearch sector
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Finance Sector{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Credit industrys
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Reasearch sector
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> Finance Sector
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className='col-lg-4 col-md-6'>
              <div className='widget widget-recent-post'>
                <h4 className='widget-title'>Contact us</h4>
                <div className='widget widget_contact'>
                  <ul className='details'>
                    <li>
                        <FaMapMarkedAlt />
                        7 Anderson Drive,
                        Cambridge <br/> ON N1R 6E6,
                        CANADA
                    </li>
                    <li className='mt-3'>
                      <FaPhoneAlt /> +1-416-476-1878
                    </li>
                    <li className='mt-2'>
                      <FaEnvelope /> immigration@thiaan.com
                    </li>
                  </ul>
                  <ul className='social-media mt-4'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>© Weleden 2023 | All Rights Reserved</p>
              </div>
              <div className='col-md-6 text-lg-end'>
                <a href='#'>Terms &amp; Condition</a>
                <a href='#'>Privacy Policy</a>
                <a href='#'>Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterTwo;
