import React, { useState } from "react";
import { Link } from "react-router-dom";
import servicelist from "../scripts/service";
import countrylist from "../scripts/country";

const NavBar = () => {
  const [active, setActive] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };

  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  return (
    <>
      {/* navbar start */}
      <nav className='navbar navbar-area navbar-expand-lg'>
        <div className='container nav-container navbar-bg'>
          <div className='responsive-mobile-menu'>
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target='#itech_main_menu'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
          </div>
          <div className='logo'>
            <Link to='/'>
              <img src='../assets/img/logo/trans_logo_125x125.png' alt='img' />
            </Link>
          </div>
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id='itech_main_menu'
          >
            <ul className='navbar-nav menu-open text-lg-end'>
              <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/about'>About</Link>
              </li>
              <li className='menu-item-has-children'>
                <a href='/service'>Service</a>
                <ul className='sub-menu'>
                  {servicelist.map((data, index) => (
                    <li key={index}>
                      <Link to={`/service/${data.url}`}>{data.title}</Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li className='menu-item-has-children'>
                <Link to="">Country</Link>
                <ul className='sub-menu'>
                  {countrylist.map((data, index) => (
                    <li key={index}>
                      <Link to={`/country/${data.url}`}>{data.name}</Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <Link to='/contact'>Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className='nav-right-part nav-right-part-desktop align-self-center'>
            <a className='navbar-phone' href='tel:'>
              <span className='icon'>
                <img src='/assets/img/icon/1.png' alt='phone' />
              </span>
              <span>Let's Connect</span>
            </a>
          </div>
        </div>
      </nav>
      {/* navbar end */}
    </>
  );
};

export default NavBar;
