let servicelist = [
  {
    title: "Study Abroad",
    des: "Explore Global Learning Opportunities with Expert Guidance. Your Educational Journey Begins Here.",
    img: "/assets/img/service-icon/1.png",
    icon: "/assets/img/service-icon/study_abroad.png",
    url: "study-abroad",
    banner: "/assets/img/service_header/study_abroad.png",
    content: "At Weleden Immigration, we understand that studying abroad is a life-changing experience that opens doors to endless opportunities and enriches your academic and personal journey. Our comprehensive Study Abroad services are designed to guide you through every step of the process, ensuring a seamless transition to your dream educational destination. Explore the world with Weleden Immigration and make your academic dreams a reality.",
    faqs: [
      {
        question: "How to choose the right study destination?",
        answer: "Consider factors such as your academic goals, preferred language of instruction, budget, and cultural preferences. We recommend seeking guidance from our experts to make an informed decision."
      },
      {
        question: "What are the eligibility criteria for studying abroad?",
        answer: "Eligibility criteria vary by country, institution, and program. Typically, you need to meet academic requirements, language proficiency standards, and provide necessary documentation. Our experts can assist you in understanding specific criteria for your chosen destination."
      },
      {
        question: "How can Weleden Immigration help me in the application process?",
        answer: "Weleden Immigration provides comprehensive support throughout the application process, including expert consultation, university selection, document preparation, and guidance on visa applications. Our goal is to simplify the process and increase your chances of successful admission."
      },
      {
        question: "Are scholarships available for international students?",
        answer: "Many institutions offer scholarships and financial aid for international students. We can help you explore and apply for relevant scholarships to ease the financial burden of studying abroad."
      },
      {
        question: "How do I manage my finances while studying abroad?",
        answer: "We recommend careful financial planning, including estimating tuition and living expenses. Additionally, explore part-time work opportunities, scholarships, and financial aid options. Our team can provide guidance on budgeting and financial management."
      }
    ],
    conclude: "Embark on your global academic journey with confidence, guided by the expertise of Weleden Immigration. Our commitment is to make your study abroad experience memorable, enriching, and successful.",
    points: ["Check Academic Requirements", "Plan your Finance", "Language Proficiency", "Get Health Insurance", "Open to Cultural Adaptation"]
  },
  {
    title: "Work Permits",
    des: "Navigate International Careers Effortlessly. Secure Your Work Permit for Global Employment Success.",
    img: "/assets/img/service-icon/2.png",
    icon: "/assets/img/service-icon/work_permits.png",
    url: "work-permits",
    banner: "/assets/img/service_header/work_permit.png",
    content: "At Weleden Immigration, we understand the significance of obtaining a work permit, and we are committed to simplifying the process for you. Whether you are an individual seeking employment opportunities abroad or an employer looking to hire international talent, we are here to guide you through the intricacies of work permit applications.",
    faqs: [
      {
        question: "What is a work permit, and why is it necessary?",
        answer: "A work permit is an official document issued by a government that allows foreign nationals to legally work in a specific country. It is necessary to ensure that individuals are authorized to work in compliance with local regulations."
      },
      {
        question: "Who needs a work permit?",
        answer: "Generally, anyone working in a foreign country who is not a citizen or permanent resident will need a work permit. This includes both employees and self-employed individuals."
      },
      {
        question: "How long does it take to obtain a work permit?",
        answer: "The processing time varies depending on the country and the type of work permit. Our team at Weleden Immigration will provide you with an estimate based on your specific circumstances."
      }
    ],
    conclude: "We are committed to making your work permit application process as smooth and stress-free as possible.",
    points: ["Research Local Regulations", "Prepare Accurate Documentation", "Seek Professional Assistance", "Stay Informed"]
  },
  {
    title: "Family Sponsorship",
    des: "Reunite Loved Ones Worldwide. Streamlined Processes for Bringing Families Closer, Wherever They Are.",
    img: "/assets/img/service-icon/3.png",
    icon: "/assets/img/service-icon/family_sponsorship.png",
    url: "family-sponsorship",
    banner: "/assets/img/service_header/family_sponsorship.png",
    content: "At Weleden Immigration, we understand the significance of family reunification, and we are dedicated to facilitating the process of family sponsorship for our clients. Whether you are a permanent resident or a citizen looking to reunite with your loved ones, our experienced team is here to guide you through the intricacies of family sponsorship applications.",
    faqs: [
      {
        question: "Who can be sponsored under family sponsorship?",
        answer: "Family sponsorship typically includes spouses, common-law or conjugal partners, dependent children, parents, and grandparents. The eligibility criteria may vary based on the sponsoring individual's status."
      },
      {
        question: "What are the financial requirements for family sponsorship?",
        answer: "Sponsors are generally required to demonstrate that they can financially support the sponsored family members. This includes meeting minimum income requirements to ensure the well-being of the sponsored individuals."
      },
      {
        question: "How long does the family sponsorship process take?",
        answer: "The processing time varies based on factors such as the relationship between the sponsor and the sponsored person, the country of residence, and the type of family sponsorship application. Our team will provide you with an estimate based on your specific circumstances."
      },
      {
        question: "Can I sponsor other relatives, such as siblings, under family sponsorship?",
        answer: "While the eligibility criteria may vary by country, many immigration programs focus on immediate family members. Sponsoring other relatives, such as siblings, may have different requirements and may not be covered under standard family sponsorship programs."
      }
    ],
    conclude: "Weleden Immigration is committed to reuniting families and making the family sponsorship process as seamless as possible.",
    points: ["Check Eligibility Criteria", "Prepare Accurate Documentation", "Submit on Time", "Stay in Touch with Weleden"]
  },
  {
    title: "Business Immigration",
    des: "Elevate Your Business Globally. Tailored Immigration Solutions for Entrepreneurial Success Anywhere in the World.",
    img: "/assets/img/service-icon/2.png",
    icon: "/assets/img/service-icon/business_immigration.png",
    url: "business-immigration",
    banner: "/assets/img/service_header/business_immigration.png",
    content: "Weleden Immigration is dedicated to assisting entrepreneurs, investors, and businesses in navigating the complexities of business immigration. Whether you are looking to establish a business, invest in a new market, or transfer key personnel, our experienced team is here to guide you through the intricacies of business immigration processes.",
    faqs: [
      {
        question: "What is business immigration, and who can benefit from it?",
        answer: "Business immigration involves individuals or businesses moving across borders for entrepreneurial, investment, or corporate purposes. Entrepreneurs, investors, and businesses looking to expand or relocate internationally can benefit from business immigration programs."
      },
      {
        question: "What are the key business immigration programs available?",
        answer: "Common business immigration programs include Investor Visa, Entrepreneur Visa, and Corporate Transfer programs. These programs vary by country and offer different pathways for individuals and businesses."
      },
      {
        question: "What are the financial requirements for business immigration?",
        answer: "Financial requirements for business immigration programs may include investment thresholds, proof of business viability, or demonstrating the ability to create jobs. Our team can guide you through the specific financial criteria for your chosen program."
      },
      {
        question: "How long does it take to process a business immigration application?",
        answer: "Processing times for business immigration applications vary based on the country and program. Our experienced team at Weleden Immigration will provide you with a timeline based on your unique circumstances."
      }
    ],
    conclude: "Weleden Immigration is committed to being your trusted partner in achieving your business immigration objectives.",
    points: ["Research Destination Country", "Prepare Business Plan", "Legal Compliance", "Seek Professional Guidance"]
  },
  {
    title: "Visitors' Visa",
    des: "Hassle-Free Travel. Unlock Seamless Visa Solutions for Your International Exploration and Family Connections.",
    img: "/assets/img/service-icon/1.png",
    icon: "/assets/img/service-icon/visitor_visa.png",
    url: "visitor-visa",
    banner: "/assets/img/service_header/visitors_visa.png",
    content: "At Weleden Immigration, we understand the importance of hassle-free travel for both leisure and business purposes. Our dedicated team is committed to providing efficient Visitor's Visa services, ensuring that your travel plans are executed smoothly and in compliance with immigration regulations.",
    faqs: [
      {
        question: "What is a Visitor's Visa, and who needs it?",
        answer: "A Visitor's Visa is an official document issued by a country that allows individuals to enter for a temporary stay. It is required for those who intend to visit a foreign country for tourism, family visits, or business purposes."
      },
      {
        question: "How long does it take to process a Visitor's Visa application?",
        answer: "The processing time for Visitor's Visas varies depending on the destination country and the type of visa. Generally, it is advisable to apply well in advance of your intended travel date."
      },
      {
        question: "What documents are typically required for a Visitor's Visa application?",
        answer: "Commonly required documents include a valid passport, visa application form, proof of financial ability, travel itinerary, and proof of accommodation. The specific requirements may vary by country."
      },
      {
        question: "Can I extend my Visitor's Visa if needed?",
        answer: "Some countries allow extensions for Visitor's Visas under certain circumstances. It's important to check the specific rules of the destination country and plan accordingly."
      }
    ],
    conclude: "Weleden Immigration is dedicated to making your travel aspirations a reality with our streamlined Visitor's Visa services.",
    points: ["Prepare Travel Itinerary", "Provide Financial Proof", "Understanding Visa Conditions", "Apply Early"]
  },
  {
    title: "Permanent Residency",
    des: "Make Your Global Stay Permanent. Simplified Processes for Achieving Permanent Residency Anywhere You Choose.",
    img: "/assets/img/service-icon/3.png",
    icon: "/assets/img/service-icon/permanent_residency.png",
    url: "permanent-residency",
    banner: "/assets/img/service_header/permanent_residency.png",
    content: "At Weleden Immigration, we understand the significance of obtaining Permanent Residency (PR), and we are dedicated to guiding individuals and families through the process. Whether you're seeking to establish a new life in a different country or secure your future in your current place of residence, our experienced team is here to navigate the complexities of Permanent Residency applications.",
    faqs: [
      {
        question: "What is Permanent Residency, and why is it beneficial?",
        answer: "Permanent Residency is a status granted by a country that allows individuals to live and work in that country on a permanent basis. It offers the flexibility to stay indefinitely, access social benefits, and often leads to citizenship eligibility."
      },
      {
        question: "What are the common eligibility criteria for Permanent Residency?",
        answer: "Eligibility criteria may include factors such as employment history, educational qualifications, language proficiency, and the ability to support oneself financially. Specific requirements vary by country."
      },
      {
        question: "How long does it take to obtain Permanent Residency?",
        answer: "The processing time for Permanent Residency applications varies by country and program. Our team will provide you with an estimate based on your individual circumstances and the specific requirements of the destination country."
      },
      {
        question: "Can I travel while my Permanent Residency application is in process?",
        answer: "Many countries allow applicants to travel while their Permanent Residency application is being processed. However, it's essential to be aware of any restrictions or guidelines provided by the immigration authorities."
      }
    ],
    conclude: "Weleden Immigration is committed to turning your Permanent Residency aspirations into a reality.",
    points: ["Check Eligibility Criteria", "Prepare Accurate Documentation", "Understand Obligations", "Plan for the Future"]
  },
];

export default servicelist;
