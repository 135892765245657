let teamList = [
    {
        name: "Reena Pol",
        des: "Welcome to Weleden, where Reena Pol, our founder and accomplished immigration consultant, pioneers a path for individuals seeking a seamless immigration experience. With a wealth of expertise, Reena leads our team with compassion and dedication. As the visionary behind Weleden, she blends years of industry knowledge with a commitment to ethical practices. Reena's personalized approach ensures each client's unique journey is met with tailored strategies, making Weleden synonymous with reliability, integrity, and success in the complex realm of immigration. Choose Weleden for a trusted partner in realizing your immigration dreams, guided by Reena Pol's commitment to excellence and client satisfaction.",
        img: "assets/img/team/reena_pol.jpeg",
        fblink: "",
        lkinlink: "",
        instalink: "",
        twtlink: "",
    },
    {
        name: "Santonur R. Santo",
        des: "Santonur Santo is a graduate of Centennial College with a Paralegal degree and holds an LLB from Comilla University, Dhaka, Bangladesh. His global perspective is amplified by an International Law Certificate from the International Business Management Institute, Berlin, Germany. Beyond legal expertise, Santo is a driving force in Weleden's marketing, leveraging strategic approaches and a passion for brand promotion. Choose Weleden for a seamless immigration experience, guided by Santo's legal acumen and innovative marketing strategies, ensuring a comprehensive and successful journey.",
        img: "assets/img/team/santonur_santo.jpeg",
        fblink: "",
        lkinlink: "",
        instalink: "",
        twtlink: "",
    },
    
  ];
  export default teamList;
  