import React from "react";

const CountryDetailsArea = ({ data }) => {
  return (
    <>
      <div className='blog-area pd-top-100 pd-bottom-100'>
        <div className='container'>
          <div className='row'>
            <div className='blog-details-page-content'>
              <div className='single-blog-inner'>
                <div className='thumb'>
                  <img src={data.banner} alt='img' />
                </div>
                <div className='details'>
                  <h3>{data.head}</h3>
                  <p>{data.des}</p>
                  <div className='row'>
                    {data.img.map((img, index) => (
                      <div key={index} className='col-md-4'>
                        <div className='thumb'>
                          <img src={img} alt={`img-${index}`} />
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Loop through service array */}
                  {data.service.map((service, index) => (
                    <div
                      key={index}
                      className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id={`heading${index}`}>
                          <button
                            className='accordion-button'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target={`#collapse${index}`}
                            aria-expanded={index === 0 ? "true" : "false"}
                            aria-controls={`collapse${index}`}
                          >
                            {service.heading}
                          </button>
                        </h2>
                        <div
                          id={`collapse${index}`}
                          className={`accordion-collapse collapse ${
                            index === 0 ? "show" : ""
                          }`}
                          aria-labelledby={`heading${index}`}
                        >
                          <div className='accordion-body'>{service.des}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* Loop through content array */}
                  {data.content.map((item, index) => (
                    <div key={index}>
                      <h4>{item.heading}</h4>
                      <p>{item.content}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountryDetailsArea;
