import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import teamList from "../scripts/team"

const Team = () => {
  return (
    <>
      <div className='team-area bg-relative pd-top-90 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>MEET OUR EXPERTS</h6>
            <h2 className='title'>
              That's our <span>Team</span> that will assist you to execute your plan!
            </h2>
          </div>
          {teamList.map((member) => (
            <div className='team-details-page' key={member.id}>
              <div className='row mt-5'>
                <div className='col-lg-5 text-center'>
                  <div className='thumb mb-4 mb-lg-0'>
                    <img src={member.img} alt={member.name} />
                  </div>
                </div>
                <div className='col-lg-7 align-self-center ps-xl-5'>
                  <h3 className='mb-2'>{member.name}</h3>
                  <div className='details mt-4'>
                    <h4>About Me</h4>
                    <p>{member.des}</p>
                  </div>
                  <ul className='social-media style-base mt-4 pt-2'>
                  <li>
                    <a href={member.fblink}>
                      <FaFacebookF aria-hidden='true' />
                    </a>
                  </li>
                  <li>
                    <a href={member.twtlink}>
                      <FaTwitter aria-hidden='true' />
                    </a>
                  </li>
                  <li>
                    <a href={member.instalink}>
                      <FaInstagram aria-hidden='true' />
                    </a>
                  </li>
                  <li>
                    <a href={member.lkinlink}>
                      <FaLinkedinIn aria-hidden='true' />
                    </a>
                  </li>
                </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Team;
