import React from "react";
import { Link } from "react-router-dom";
import countrylist from "../scripts/country"; // Update the path based on your project structure

const WorkProcessOne = () => {
  return (
    <>
      <div className='work-process-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>GLOBAL NETWORK</h6>
            <h2 className='title'>
              Tailoring solutions <span>Globally</span>
            </h2>
          </div>
          <div className='row'>
            {countrylist.map((country, index) => (
              <div className='col-xl-2 col-md-6' key={index}>
                <Link to={`/country/${country.url}`}>
                  <div className='single-work-process-inner'>
                    <div className='thumb mb-3'>
                      <img src={country.icon} alt={country.name} />
                    </div>
                    <div className='details'>
                      <p className='process-count'>{country.name}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkProcessOne;
