import React from "react";
import { Link } from "react-router-dom";
import serviceList from "../scripts/service";

const ServiceAreaFive = () => {
  const totalServices = serviceList.length;
  const servicesOnLeft = Math.ceil(totalServices / 2);

  const renderServices = (startIndex, endIndex, isLeft) => {
    return serviceList.slice(startIndex, endIndex).map((data, index) => (
      <Link to = {`/service/${data.url}`}>
        <div className={`single-service-inner-3 single-service-inner-3-${isLeft ? 'left' : 'right'}`}  key={index}>
          <div className='thumb'>
            <div className='thumb-inner'>
              <img src={data.icon} alt='img' />
            </div>
          </div>
          <div className='details'>
            <h5 className='mb-3'>{data.title}</h5>
            <p className='mb-0'>{data.des}</p>
          </div>
        </div>
      </Link>  
    ));
  };

  return (
    <>
      <div className='service-area bg-gray bg-relative pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>SERVICES</h6>
                <h2 className='title'>How we plan to serve you!</h2>
              </div>
            </div>
          </div>
          <div className='row custom-no-gutter'>
            <div className='col-lg-4 col-md-6'>
              {renderServices(0, servicesOnLeft, true)}
            </div>
            <div className='col-lg-4 col-md-6 bg-grey-right d-lg-inline-block d-none'>
              <div className='service-thumb service-middle-section align-self-end'>
                <img src='assets/img/service/service_overview.png' alt='img' />
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              {renderServices(servicesOnLeft, totalServices, false)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceAreaFive;
